import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { MemoryRouter as Router } from 'react-router-dom';
import * as Yup from 'yup';
import { ReactComponent as ErrorSVG } from '~/Assets/settings_tab.svg';
import './App.css';
import ConfigurationError from './Components/Error';
import createStore from './Redux';
import Routes from './Routes';
import { queryParamsToObject, canSplit, getStorage, removeStorage } from './Services/Helpers';
import { I18nProvider } from './Services/i18n';
import { AppContext, AppContextProvider, NotificationContextProvider, ThemeContextProvider, UserContextProvider } from './Services/Providers';
import ErrorBoundary from './Components/ErrorBoundaries';
import { isEmpty } from './Services/Helpers';
import { FormattedMessage } from 'react-intl';
import { validationEmailSchema, validationSchema } from './Services/SharedData/UrlValidationSchema';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { createAPI } from './Services/Api';
import LocaleHelper from './Services/Helpers/LocaleHelper';
import { StorageKeys } from '~/Configs/Storage';

const store = createStore();

function App() {
  let valid = canSplit(window.location.href, '?');
  //let settings = { ...document.getElementById('wink-online-module')?.dataset }; //this should not be pushed in prod, only for test
  let settings = {};
  if (valid) {
    let [url, params] = window.location.href.split('?');
    const paramsObj = queryParamsToObject(params);
    if (paramsObj.lang) {
      LocaleHelper.setSelectedLocale(paramsObj.lang);
    }
    settings = {
      ...settings,
      ...{
        baseURL: process.env.REACT_APP_BASE_URL,
        themename: document.getElementById('wink-online-module')?.dataset?.themename, 
        lang: 'en',
        origin: window.location.href,
        googleAnalyticsTrackingId: 'G-Z6Q76G0M7Y',
        gtmId: 'GTM-TSDMRX5',
        ...paramsObj,
      },
    };
  }
  const lastAccountsId = JSON.parse(getStorage( StorageKeys.userContext))?.accountId;

  useEffect(()=>{
    if(lastAccountsId){
      if(settings && settings.id){
        if(settings.id!==lastAccountsId){
          window.localStorage.clear();
          window.location.reload();
        }
      }
    }
  },[])

  if (isEmpty(settings.baseURL)) {
    settings.baseURL = settings.baseurl;
  }
  delete settings.baseurl;

  if (isEmpty(settings.storeId)) {
    settings.storeId = settings.storeid;
  }
  delete settings.storeid;

  if (isEmpty(settings.googleAnalyticsTrackingId)) {
    settings.googleAnalyticsTrackingId = 'G-Z6Q76G0M7Y';
  } 

  if (isEmpty(settings.gtmId)) {
    settings.gtmId = 'GTM-NNK9GQQ';
  }

  TagManager.initialize({
    gtmId: settings.gtmId,
  });

  createAPI(settings.baseURL);

  if (validationEmailSchema.isValidSync(settings)) {
    delete settings.token;
    ReactGA.initialize(settings.googleAnalyticsTrackingId);
    settings.id = settings.accountsId;
    settings.name = settings.companyName;
  }
  localStorage.removeItem('data-form-RegisterForm');
  localStorage.removeItem('data-form-NewPatientForm');
  valid = validationSchema.isValidSync(settings) || validationEmailSchema.isValidSync(settings);

  /*
  window.addEventListener("message", event=>{
    console.log(event.data);
  });
  */
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <NotificationContextProvider>
          <UserContextProvider
            id={settings.id}
            name={settings.name}
            storeId={settings.storeId}
            origin={settings.origin}
            themename={settings.themename}
          >
            <AppContextProvider>
              <I18nProvider>
                <ThemeContextProvider themename={settings.themename}>
                  {valid ? (
                    <Router>
                      <Routes />
                    </Router>
                  ) : (
                    <ConfigurationError
                      SVG={ErrorSVG}
                      ErrorText={<FormattedMessage id='configsError' />}
                      size='50%'
                    ></ConfigurationError>
                  )}
                </ThemeContextProvider>
              </I18nProvider>
            </AppContextProvider>
          </UserContextProvider>
        </NotificationContextProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
