import formStyles from '../../../GeneralStyles/formStyle';

const useStyles = (theme) => ({
  ...formStyles(theme),
  forgotPasswordWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  forgotButton: {
    textTransform: 'none !important',
    cursor:'pointer',
    fontWeight:'500',
    marginLeft:'auto !important',
    textAlign:'right', 
    paddingRight:`${theme.spacing(1)}px !important`, 
    color:`${theme.palette.primary.main} !important`
  },
  version: {
    width: "100%",
    textAlign: 'right',
    paddingRight:`${theme.spacing(1)}px !important`, 
    fontSize: '0.7rem'
  }
});

export default useStyles;
